import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import 'babel-polyfill';
import { default as cookie, default as Cookies } from 'js-cookie';
import moment from 'moment';
import arLocale from 'moment/locale/ar';
import withRedux from 'next-redux-wrapper';
import App, { Container } from 'next/app';
import getConfig from 'next/config';
import Router from 'next/router';
import NProgress from 'nprogress';
import { Fragment } from 'react';
import { connect, Provider } from 'react-redux';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import rtlPlugin from 'stylis-rtlcss';
import { theme } from '../components/Constants';
import Head from '../components/Head';
import Loading from '../components/Loading';
import { appWithTranslation, i18n } from '../i18n';
import '../static/style/media.scss';
import '../static/style/styles.scss';
import { initializeStore } from '../store';
import { loadProfile } from '../store/actions/profile';
import { initGA, logEvent } from '../utils/analytics';
import { getUrl } from '../utils/api';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: true,
    environment: process.env.API_URL.includes('api-dev')
      ? 'development'
      : 'production',
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

Router.events.on('routeChangeStart', url => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

class MyApp extends App {
  state = {
    mounted: false,
    fontLoaded: false,
    currentAPI: process.env.API_URL,
    currentAPI_Reach: process.env.REACHNETWORK_API_URL,
    currentReachURL: process.env.REACHNETWORK_URL,
    currentCookie: Cookies.get('token'),
  };
  constructor(props) {
    super(props);
    this.componentGracefulUnmount = this.componentGracefulUnmount.bind(this);
  }

  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    // this exposes the query to user
    pageProps.query = ctx.query;
    return { pageProps };
  }

  componentGracefulUnmount() {
    this.setState({ mounted: false });

    window.removeEventListener('beforeunload', this.componentGracefulUnmount);
  }

  componentDidMount() {
    document.fonts.ready.then(() => {
      this.setState({
        fontLoaded: true,
      });
    });

    // make sure the componentWillUnmount of the wrapped instance is executed even if React
    // does not have the time to unmount properly. we achieve that by
    // * hooking on beforeunload for normal page browsing
    // * hooking on turbolinks:before-render for turbolinks page browsing

    window.addEventListener('beforeunload', this.componentGracefulUnmount);
    this.setState({ mounted: true });

    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }

    if (window.localStorage.getItem('login_time') == null) {
      window.localStorage.setItem('login_time', moment(new Date()));
    }

    // Handle dev.reachnetwork.co || reachnetwork.co
    // let currentHost = window.location.host.includes('dev.');
    // this.setState({
    //   currentAPI: currentHost ? process.env.API_DEV_URL : process.env.API_URL,
    //   currentAPI_Reach: currentHost
    //     ? process.env.REACHNETWORK_API_DEV_URL
    //     : process.env.REACHNETWORK_API_URL,
    //   currentReachURL: currentHost
    //     ? process.env.REACHNETWORK_URL_DEV
    //     : process.env.REACHNETWORK_URL,
    // });
    logEvent(
      'ReachPlusAnalyticsIntegrityTest',
      'ReachPlusAnalyticsIntegrityTest'
    );
    window.addEventListener('beforeunload', function() {
      var diffInDays =
        moment().diff(window.localStorage.getItem('login_time'), 'days') + 'd';
      var diffInMin =
        moment().diff(window.localStorage.getItem('login_time'), 'minutes') +
        'm';

      window.localStorage.setItem('logout', Date.now());
      logEvent(
        'session',
        `${window.localStorage.getItem(
          'username'
        )}-duration(${diffInDays}h:${diffInMin}m)`
      );
      window.localStorage.removeItem('login_time');
    });

    const reachAccounts = window.localStorage.getItem('reachAccounts');
    if (!reachAccounts) {
      getUrl(`${this.state.currentAPI_Reach}/profile`, 'reach_token')
        .then(profile => {
          const access_token = cookie.get('token');
          const reach_access_token = cookie.get('reach_token');

          const accountList = [
            {
              token: access_token,
              reach_token: reach_access_token,
              profile: profile.data,
              isActive: true,
            },
          ];
          window.localStorage.setItem(
            'reachAccounts',
            JSON.stringify(accountList)
          );
        })
        .catch(err => console.log(err));
    }

    // var Tawk_API = Tawk_API || {},
    //   Tawk_LoadStart = new Date();
    // (function() {
    //   var s1 = document.createElement('script'),
    //     s0 = document.getElementsByTagName('script')[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5e5396aa298c395d1ce97519/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();

    // setTimeout(
    //   function() {
    //     //Start the timer
    //     if (document.querySelector('iframe[title="chat widget"]')) {
    //       if (window.innerWidth < 768) {
    //         document.querySelector('iframe[title="chat widget"]').style.bottom =
    //           '57px';
    //         document.querySelector('iframe[title="chat widget"]').style.right =
    //           '0px';
    //         console.log('window.innerWidth', window.innerWidth);
    //       }
    //     }
    //   }.bind(this),
    //   4000
    // );

    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_API.customStyle = {
      visibility: {
        mobile: {
          position: 'br',
          xOffset: 0,
          yOffset: 57,
        },
        bubble: {
          rotate: '0deg',
          xOffset: -20,
          yOffset: 0,
        },
      },
    };
  }

  componentWillUnmount() {
    this.componentGracefulUnmount();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      state: { currentCookie, currentAPI_Reach },
      props: { dispatch },
    } = this;

    if (currentCookie !== prevState.currentCookie) {
      dispatch(loadProfile(currentAPI_Reach));
    }
  }

  render() {
    const {
      props: { Component, store, pageProps, profileData, err },
      state: {
        mounted,
        currentAPI,
        currentAPI_Reach,
        currentReachURL,
        fontLoaded,
      },
    } = this;

    if (i18n.language === 'ar') {
      moment.locale('ar', arLocale);
    } else {
      moment.locale('en');
    }

    return (
      <Fragment>
        <Head />
        {mounted && fontLoaded ? (
          <Container>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <div
                  className={`full-content-wrapper ${
                    i18n.language == 'ar' ? 'rtl ' : 'ltr '
                  }`}
                >
                  <StyleSheetManager
                    stylisPlugins={i18n.language === 'ar' ? [rtlPlugin] : []}
                  >
                    <Component
                      isArabic={i18n.language == 'ar'}
                      currentAPI={currentAPI}
                      currentAPI_Reach={currentAPI_Reach}
                      currentReachURL={currentReachURL}
                      profileData={profileData}
                      dispatch={this.props.dispatch}
                      err={err}
                      {...pageProps}
                    />
                  </StyleSheetManager>
                  <TawkMessengerReact
                    propertyId="5e5396aa298c395d1ce97519"
                    widgetId="default"
                  />
                </div>
              </ThemeProvider>
            </Provider>
          </Container>
        ) : (
          <Loading />
        )}
        <style jsx global>{`
          .full-content-wrapper {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
          }
          *,
          *:before,
          *:after {
            box-sizing: border-box;
          }
          body {
            padding: 0;
            margin: 0;
            font-family: ${i18n.language == 'ar' ? 'SegUIMo' : 'Albert Sans'};
            overflow-x: hidden;
          }
           {
            /* TODO: Base Styles
            p {
              margin: 0;
            }
            a,
            a:hover,
            a:visited,
            a:link,
            a:active {
              text-decoration: none;
              outline: none;
            }

            button,
            button:focus {
              outline: none;
            }

            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
              margin: 0;
            }
           */
          }
          a {
            text-decoration: none;
            color: ${theme.colors.offBlack};
             {
              /* TODO: Base Styles
              color: inherit;
            */
            }
          }
          input {
            font-family: inherit !important;
          }
          .float-right {
            float: right;
          }
          .float-left {
            float: left;
          }
          .error-message {
            color: ${theme.colors.red};
            font-size: 14px;
            text-align: center;
          }
          .mb-10 {
            margin-bottom: 10px;
          }
          .mt-10 {
            margin-top: 10px;
          }
        `}</style>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    profileData: state.profile.profileList,
  };
};

export default withRedux(initializeStore)(
  appWithTranslation(connect(mapStateToProps)(MyApp))
);
